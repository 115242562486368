
import { IonContent, IonHeader, IonToolbar, IonButtons, IonButton, IonMenuButton, IonPage, IonTitle, IonSpinner, IonIcon } from '@ionic/vue';
import { defineComponent } from 'vue';
import { personCircleOutline, personCircleSharp } from 'ionicons/icons';


export default defineComponent({
  name: 'Confirmation',
  data () {
    return {
      code: null,
      isLoading: false,
      errorMsg: null,
      errorWhileConfirming: false,
      codeIsValid: false,
      personIos: personCircleOutline,
      personMd: personCircleSharp
    }
  },
  components: {
    IonContent,
    IonPage,
    IonToolbar,
    IonHeader,
    IonMenuButton,
    IonButtons,
    IonButton,
    IonTitle,
    IonSpinner,
    IonIcon
  }
});

