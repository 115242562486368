<template>
  <ion-page>

    <ion-header :translucent="true" class="ion-no-border">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button color="primary"></ion-menu-button>
        </ion-buttons>
        <ion-title></ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true" v-if="!isLoading">

      <ion-content :fullscreen="true">
        <div class="container sideMargins">
          <ion-icon :ios="personIos" :md="personMd" style="width: 100px; height: 100px;"></ion-icon><br><br><br>
          <strong class="capitalize">Account verifiziert</strong>
          <p>Du kannst dich jetzt einloggen.</p>
          <ion-button size="large" class="topMargins sideMargins" href="/login" expand="block" color="primary">Jetzt Einloggen</ion-button>
        </div>
      </ion-content>

    </ion-content>

    <ion-content v-if="isLoading" class="spinnerCenter">
      <ion-spinner name="dots"></ion-spinner>
    </ion-content>

  </ion-page>
</template>

<script lang="ts">
import { IonContent, IonHeader, IonToolbar, IonButtons, IonButton, IonMenuButton, IonPage, IonTitle, IonSpinner, IonIcon } from '@ionic/vue';
import { defineComponent } from 'vue';
import { personCircleOutline, personCircleSharp } from 'ionicons/icons';


export default defineComponent({
  name: 'Confirmation',
  data () {
    return {
      code: null,
      isLoading: false,
      errorMsg: null,
      errorWhileConfirming: false,
      codeIsValid: false,
      personIos: personCircleOutline,
      personMd: personCircleSharp
    }
  },
  components: {
    IonContent,
    IonPage,
    IonToolbar,
    IonHeader,
    IonMenuButton,
    IonButtons,
    IonButton,
    IonTitle,
    IonSpinner,
    IonIcon
  }
});

</script>

<style scoped>

.container {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.container strong {
  font-size: 20px;
  line-height: 26px;
}

.container p {
  font-size: 16px;
  line-height: 22px;
  color: #8c8c8c;
  margin: 0;
}

.container a {
  text-decoration: none;
}

ion-spinner {
  display: block;
  margin: auto;
  margin-top: 50%;
}

.sideMargins {
  margin-left: 25px;
  margin-right: 25px;
}

.topMargins {
  margin-top: 25%;
}

.spinnerCenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

</style>